<script setup lang="ts">
import type { PromoData } from "@/types";

defineProps<{
	locationTid?: string;
}>();

const { t } = useT();
const { isMobile } = useDevice();
const { gameOfWeek } = useHomePage();
const { promotionsData } = usePromotionsData({ server: true, immediate: false });
const { data: bonusesData } = useGetBonusesData();
const { weeklyCashbackData } = useStatus();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();
const [isOpenPopper, toggleOpenPopper] = useToggle(false);
const { emailRewards, profileRewards } = useCheckRewardsData();
const showOverlayWindow = ref(false);
const {
	depositStreakIsActive,
	prizeVaultIsActive,
	scratchCardsIsActive,
	rankLeagueIsActive,
	cosmicBoxesIsActive,
	seasonTowerIsActive
} = useFeaturesIsActive();

const activeOffers = computed(() => {
	const filteredOffers = promotionsData.value.promotions?.reduce<PromoData[]>((acc, { data }) => {
		if (
			(data?.promoOfferPreset?.subType || data?.promoOfferPreset?.mostPopular || data?.promoOfferPreset?.bestDeal) &&
			data
		) {
			acc.push(data);
		}
		return acc;
	}, []);

	return filteredOffers?.sort((paymentA, paymentB) => Number(paymentB?.money) - Number(paymentA?.money));
});

const wheelDurationLeftData = computed(() => bonusesData.value?.dailyWheel?.reasonValue ?? "");
const { durationLeft: wheelDurationLeft, reset } = useCountdown({
	timestamp: wheelDurationLeftData.value,
	formatToken: "HH:mm:ss"
});

const emeilVerifyAccountActive = computed(
	() =>
		!emailRewards.value?.completed && ((emailRewards.value?.coins || 0) > 0 || (emailRewards.value?.entries || 0) > 0)
);
const profileVerifyAccountActive = computed(
	() =>
		!profileRewards.value?.completed &&
		((profileRewards.value?.coins || 0) > 0 || (profileRewards.value?.entries || 0) > 0)
);

const activePromoNumber = computed(() =>
	[
		!!gameOfWeek.value?.id,
		depositStreakIsActive.value,
		prizeVaultIsActive.value,
		scratchCardsIsActive.value,
		bonusesData.value?.dailyWheel?.visible && (!wheelDurationLeftData.value || wheelDurationLeftData.value === "empty"),
		rankLeagueIsActive.value,
		cosmicBoxesIsActive.value,
		seasonTowerIsActive.value
	].reduce((total, condition) => total + (condition ? 1 : 0), 0)
);

const activeVerify = computed(() => {
	const activeConditions = [emeilVerifyAccountActive.value, profileVerifyAccountActive.value];

	return activeConditions.reduce((count, isActive) => count + (isActive ? 1 : 0), 0);
});

const counterNumber = computed(() => activePromoNumber.value + activeOffers.value?.length + activeVerify.value || 0);

const isOpenPopperRewards = useState("isOpenPopperRewards");

const checkScrollItems = (open = false) => {
	isOpenPopperRewards.value = open;

	if (!open) {
		document.body.classList.remove("overlay-hidden");
		return;
	}

	if (isMobile) {
		document.body.classList.add("overlay-hidden");
	}
};

watch(
	() => isOpenPopper.value,
	async (newValue) => {
		await nextTick();
		checkScrollItems(newValue);

		if (newValue) {
			reset(wheelDurationLeftData.value);
			dispatchGAEvent({
				event: "open_popup",
				form_name: "reward_cabinet",
				location: "header"
			});
			return;
		}
		dispatchGAEvent({
			event: "close_popup",
			form_name: "reward_cabinet",
			location: "header"
		});
	}
);

const handleOpenPromotions = (event: MouseEvent) => {
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				button_name: "promotions",
				location: "reward_cabinet"
			});
			navigateTo("/promotions");
		},
		fail: () => {
			open("LazyOModalSignup");
			event.preventDefault();
			event.stopPropagation();
		}
	});
};

const handlePopperOpen = () => {
	toggleOpenPopper();
	showOverlayWindow.value = isMobile;
};

const handlePopperClose = () => {
	toggleOpenPopper();
	setTimeout(() => {
		showOverlayWindow.value = false;
	}, 300);
};

onMounted(() => {
	checkScrollItems(isOpenPopper.value);
});
</script>
<template>
	<div :class="['rewards-wrapper', { 'popper-open': isOpenPopper }]">
		<APopper placement="bottom-start" @open:popper="handlePopperOpen" @close:popper="handlePopperClose">
			<div class="btn-rewards">
				<slot />
				<ClientOnly>
					<ABadge class="btn-counter" background="var(--custom-13)" :size="14">
						<AText class="color-custom-9" variant="ternopil" :modifiers="['bold']">{{ counterNumber }}</AText>
					</ABadge>
				</ClientOnly>
			</div>
			<template #content="{ close }">
				<div class="rewards">
					<div class="top">
						<div>
							<AText
								class="color-neutral-100"
								variant="tanta"
								:data-tid="locationTid ? `available-rewards-${locationTid}` : null"
								:modifiers="['bold']"
							>
								{{ t("Available Rewards") }}
							</AText>
							<ABadge background="var(--custom-rgb-6)" variant="info" class="badge" autosize>
								<AText
									class="color-additional-a-3"
									variant="toledo"
									:modifiers="['semibold', 'condensed']"
									:data-tid="locationTid ? `count-rewards-${locationTid}` : null"
								>
									{{ counterNumber }}
								</AText>
							</ABadge>
						</div>
						<NuxtIcon
							class="close"
							name="24/close"
							:data-tid="locationTid ? `close-rewards-${locationTid}` : null"
							@click="close"
						/>
					</div>
					<AButton
						variant="primary"
						class="button"
						:modifiers="['wide']"
						:data-tid="locationTid ? `view-all-rewards-${locationTid}` : null"
						@click="handleOpenPromotions"
					>
						<AText :modifiers="['uppercase']">{{ t("View all promo") }}</AText>
					</AButton>
					<div class="items">
						<MRewardsSpookySeason v-if="seasonTowerIsActive" @click="close" />
						<MRewardsCosmicBoxes v-if="cosmicBoxesIsActive" />
						<MRewardsGameOfWeek v-if="gameOfWeek?.id" :data="gameOfWeek" @click="close" />
						<MRewardsVipClub v-if="rankLeagueIsActive && weeklyCashbackData" />

						<MRewardsWheelOfWins
							v-if="depositStreakIsActive"
							:data-tid="locationTid ? `win-wheel-${locationTid}` : null"
						/>
						<MRewardsPrizeVault
							v-if="prizeVaultIsActive"
							:data-tid="locationTid ? `prize-vault-${locationTid}` : null"
						/>

						<MRewardsDailyWheel
							v-if="bonusesData?.dailyWheel?.visible"
							:data="bonusesData"
							:wheelDurationLeft="wheelDurationLeft"
							:location-tid="locationTid"
							:data-tid="locationTid ? `lucky-wheel-${locationTid}` : null"
						/>
						<MRewardsScratchCards v-if="scratchCardsIsActive" />
						<MRewardsVerifyAccount
							v-if="emeilVerifyAccountActive"
							:coins="emailRewards?.coins || 0"
							:entries="emailRewards?.entries || 0"
							variant="email"
						/>
						<MRewardsVerifyAccount
							v-if="profileVerifyAccountActive"
							:coins="profileRewards?.coins || 0"
							:entries="profileRewards?.entries || 0"
							variant="profile"
						/>
						<template v-for="offer in activeOffers" :key="offer?.preset?.id">
							<MRewardsPromo
								v-if="offer"
								:data="offer"
								:location-tid="locationTid"
								:data-tid="locationTid ? `reward-${locationTid}` : null"
							/>
						</template>
					</div>
				</div>
			</template>
		</APopper>
		<AOverlay v-if="showOverlayWindow" bgColor="rgba(20, 23, 34, 0.7)" :modifiers="['fixed']" class="overlay-window" />
	</div>
</template>
<style scoped lang="scss">
.rewards-wrapper {
	position: relative;

	& > div {
		border: none !important;
		margin: 0 !important;
	}
}

.badge {
	margin-left: 4px;
	--a-badge-info-padding: 8px;
}

.btn-rewards {
	position: relative;
	cursor: pointer;

	img {
		width: 30px;
		height: 30px;
	}
	.btn-counter {
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;

		@include media-breakpoint-down(lg) {
			top: 1px;
			right: -2px;
			transform: none;
		}
	}
}

:deep(.popper) {
	--popper-theme-background-color: var(--custom-9);
	--popper-theme-background-color-hover: var(--custom-9);
	--popper-theme-box-shadow: 0 0 20px 0 var(--custom-rgb-26);
	--popper-theme-padding: 16px;
	--popper-theme-border-color: rgba(243, 29, 83, 0.6);
	border: none;

	@include gradient-border(var(--gradient-32), 2px, var(--custom-9));
	@include media-breakpoint-down(lg) {
		--popper-theme-border-radius: 16px 16px 0 0;
		--popper-theme-padding: 16px 16px 0;
		position: fixed !important;
		display: block !important;
		top: auto !important;
		bottom: -100vh !important;
		left: 0 !important;
		right: 0 !important;
		transform: none !important;
		width: 100vw;
		max-width: 100vw;
		height: auto;
		max-height: 100dvh;
		overflow-y: auto;
		transition: all 400ms ease-in-out !important;
		.popper-open & {
			bottom: 0 !important;
			left: 50% !important;
			transform: translateX(-50%) !important;
		}
		.overlay {
			z-index: -1;
		}

		@include media-breakpoint-up(lg) {
			max-width: calc(100vw - 1px);
		}

		@include media-breakpoint-down(lg) {
			left: 50% !important;
			transform: translateX(-50%) translateY(50%) !important;
		}
	}
	@include media-breakpoint-up(lg) {
		--popper-theme-border-radius: 16px;
	}
}
.rewards {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: 360px;
	}
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		.counter {
			background: var(--craiova);
			padding: 6px 8px;
			border-radius: 5px;
			margin-left: 8px;
		}
		.close {
			font-size: 28px;
			transition: all 200ms ease-in-out;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}

			&:deep(path) {
				color: #41444f;
			}
		}
	}
	.items {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: calc(100% + 32px);
		margin-left: -16px;
		padding: 0 16px;
		max-height: calc(100dvh - var(--top-height) - 160px);
		overflow-y: auto;

		@include media-breakpoint-down(lg) {
			max-height: 366px;
			padding-bottom: 16px;

			@media (max-height: 600px) {
				max-height: calc(100dvh - 160px);
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: calc(100% - 140px);
			gap: 4px;
		}

		& > div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;
			padding: 8px;
			border-radius: 12px;
			background: var(--gradient-34);
			cursor: pointer;

			&:deep(.badge) {
				--a-badge-info-height: 17px;
				margin-bottom: 6px;
				line-height: 0.7;
			}

			&:deep(.content) {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: calc(100% - 140px);
				margin-left: 4px;
			}
			&:deep(button) {
				padding: 0 6px;
				width: auto;
				min-width: 64px;
				height: 28px;
			}
		}
	}

	.button,
	.button:hover {
		margin: gutter(1.5) 0 gutter(2.5) 0;
		background: var(--custom-3);
		border-color: var(--custom-3);
		height: 36px;

		.preloader-icon {
			font-size: 24px;
		}
	}
}

.overlay-window {
	display: flex;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: inherit;
}
</style>
<style lang="scss">
.overlay-hidden {
	overflow: hidden;
}
</style>
